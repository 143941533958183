body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.roistat-promo-wrap { position: relative; padding: 5px 10px; background: #f5f5f5; opacity: 1; color: #f5f5f5; text-align: center; }
.roistat-promo-wrap:hover { opacity: 1; }
.roistat-promo { font-size: 2rem; color: #4a4a4a; }

@media only screen and (max-width: 700px) {
  .text-field {
    margin-right: 10px;
  }
}

.text-field {
  margin-right: 10px;
}